/*
    References
    https://developers.google.com/tag-manager/devguide#events
*/

/**
 * Data layer object for Google Analytics.
 * @typedef {(Array<Object>)} GADataLayer
 */

/**
 * Function to get a reference to the window data layer.
 *
 * @returns {GADataLayer} The window data layer for GA.
 */
export const getDataLayer = () => {
  window.dataLayer = window.dataLayer || [];

  return window.dataLayer;
};

/**
 * Function to track an event in Google Analytics.
 *
 * @param {Object} opts An object to pass to track the GA Event.
 */
export const trackGAEvent = (opts = {}) => {
  const defaults = {
    event: 'trackGAEvent',
  };

  getDataLayer().push({ ...defaults, ...opts });
};
