import Vue from 'vue/dist/vue.esm';
import money from 'v-money';
import math from 'mathjs/dist/math';
import { TrackerProperties, TrackerEvents, trackGAEvent } from '../trackers';

Vue.use(money, { precision: 4 });

function setupLicCalculator() {
  const modelMethods = {
    fetchData() {
      const self = this;
      const id = document.getElementById('calculate').getAttribute('data-id');
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `/umbraco/api/calculatorapi/getcalculator/${id}`);
      xhr.send();

      xhr.onloadend = function () {
        self.calc = JSON.parse(this.response);
        self.isFetching = false;
        setTimeout(() => {
          window.Invoca && window.Invoca.PNAPI.run();
        }, 100);
      };
    },
    nextPage(e) {
      // TODO: implement some type of validation check
      const pageCount = this.calc.steps.length;
      $(this.$el).toggleClass('goPrev', false);
      if (this.calc.currentPage + 1 < pageCount) {
        this.calc.currentPage += 1;
      } else if (this.isLastPage) {
        // goto summary page, etc.
        this.calc.isSummaryPage = true;
        if (!this.calc.flow.completed) {
          this.calc.flow.completed = true;
          const elapsed = new Date() - this.calc.flow.startTime;
          this.trackEvent(
            { name: 'lic-finished', measurements: { duration: elapsed } },
            true
          );
          trackGAEvent({
            [TrackerProperties.Event]: TrackerEvents.CalculationComplete,
            [TrackerProperties.Total]: this.calculated,
          });
        }
      }
      if (!this.calc.flow.started) {
        this.calc.flow.started = true;
        this.calc.flow.startTime = new Date().getTime();
        this.trackEvent({ name: 'lic-started' });
      }
    },
    prevPage(e) {
      $(this.$el).toggleClass('goPrev', true);
      if (!this.calc.isFirstPage) {
        this.calc.currentPage -= 1;
      }
    },
    trackEvent(eventObj, flush) {
      flush = flush || false;
      if (window.appInsights !== undefined) {
        window.appInsights.trackEvent(
          eventObj.name,
          eventObj.properties,
          eventObj.measurements
        );
        if (flush) {
          window.appInsights.flush();
        }
      }
    },
    normalizedArray(array, key) {
      return array.reduce((last, current) => {
        const item = {};
        item[current[key]] = current;
        return { ...last, ...item };
      }, {});
    },
    flatten(arr) {
      const self = this;
      return arr.reduce(
        (flat, toFlatten) =>
          flat.concat(
            Array.isArray(toFlatten) ? self.flatten(toFlatten) : toFlatten
          ),
        []
      );
    },
    getQuestions(steps) {
      return steps.map(step => step.questions);
    },
    getFields(questions) {
      return questions.reduce((total, question) => {
        const field = question.map(q =>
          q.fields.reduce((t, f) => t.concat(f), [])
        );
        return total.concat(field);
      }, []);
    },
  };

  Vue.filter('currency', value => {
    if (!value) return '$0';
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  });

  // eslint-disable-next-line no-new
  new Vue({
    el: '#li-calculator',
    data: { calc: [], isFetching: true },
    mounted() {
      this.fetchData();
    },
    computed: {
      isFirstPage() {
        return this.calc.currentPage === 0;
      },
      isLastPage() {
        return this.calc.currentPage === this.calc.steps.length - 1;
      },
      calculated() {
        // CURRENT CALCULATION: '[(A x B) + (C x 152380) + D + E + (F x 8755) + (G x 6260) + J – K – L  ] = M]', -- H & I were combined with F & G
        const questions = this.getQuestions(this.calc.steps);
        const fields = this.getFields(questions);
        const aliases = this.flatten(fields);
        const equationAliases = this.normalizedArray(aliases, 'alias');

        const eq = this.calc.equationSettings.equation.replace(
          /{(\w+)}/g,
          (_, k) =>
            equationAliases[k].checked
              ? 1
              : equationAliases[k].value
              ? equationAliases[k].value
              : 0
        );
        return math.evaluate(eq);
      },
    },
    methods: modelMethods,
  });
}

window.keypress = evt => {
  const theEvent = evt || window.event;
  let key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode(key);

  const regex = /[0-9]|\./;

  if (evt.target.value === 0) {
    evt.target.value = '';
  }

  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};

document.addEventListener('DOMContentLoaded', () => {
  setupLicCalculator();

  $('input.v-money').on('focus', function () {
    const $t = $(this);
    setTimeout(() => {
      $t.select();
    }, 50);
  });
});
