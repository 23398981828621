export const TrackerEvents = {
  LocationSubmission: 'LocationSubmission',
  DripFormStage: 'DripFormStage',
  BoberdooMatchStatus: 'BoberdooMatchStatus',
  FormSubmission: 'FormSubmission',
  InLineFormSubmission: 'inLineFormSubmission',
  ValidFormSubmission: 'ValidFormSubMission',
  GuideDownloaded: 'guideDownloaded',
  FormLinkClicked: 'FormLinkClicked',
  EmailFormSubmission: 'EmailFormSubmission',
  SunfireSkipClick: 'sunFireSkipClick',
  ZipCodeFormSubmit: 'ZipCodeFormSubmit',
  CalculationComplete: 'calculationComplete',
  NewsletterEmailSubmission: 'newsletterEmailSubMission',
};

export const TrackerProperties = {
  Event: 'event',
  Zip: 'Zip',
  State: 'State',
  FormId: 'FormId',
  FormLinkId: 'FormLinkId',
  FormLinkIndex: 'FormLinkIndex',
  LMKTMatchStatus: 'LMKTMatchStatus',
  LMKTLeadId: 'LMKTLeadId',
  FormSelection: 'formSelection',
  EventPage: 'EventPage',
  Total: 'total',
};
